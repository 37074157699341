<template>
  <el-container class="login">
    <el-main>
      <el-card class="login_pannel">
        <div class="login_pannel_title">
          <el-image
            style="width: 100px; height: 100%"
            :src="require('@/assets/images/logo/logo.png')"
            :fit="contain"
          ></el-image>
        </div>
        <el-divider></el-divider>
        <el-form
          ref="formData"
          :model="formData"
          :rules="rules"
          label-width="60px"
          class="login_pannel_form"
        >
          <el-form-item label="账号" prop="account">
            <el-input v-model="formData.account" placeholder="请输入账号"
              ><i slot="prefix" class="el-input__icon el-icon-user"></i
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              type="password"
              v-model="formData.password"
              autocomplete="off"
              placeholder="请输入密码"
              @keyup.enter.native="submitForm('formData')"
              ><i slot="prefix" class="el-input__icon el-icon-key"></i
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="validcode">
            <el-input
            	class = "vcode"
            	style = "width: 60%;display: inline-block;"
              v-model="formData.validcode"
              autocomplete="off"
              placeholder="请输入验证码"
              @keyup.enter.native="submitForm('formData')"
              ><i slot="prefix" class="el-input__icon el-icon-arrow-right"></i
            ></el-input>
            <el-tooltip content="看不清？点击更换另一张" placement="bottom" effect="light" >
            	<el-image
		        		class = "vcode"
		            style="width: 100px; height: 40px; margin-left: 20px;float: right;"
		            :src="imgBase64Code"
		            :fit="contain"
		            @click="getImg()"
	      			></el-image>
            </el-tooltip>

          </el-form-item>
        </el-form>
        <div class="login_pannel_button">
          <el-button
            type="primary"
            @click="submitForm('formData')"
            :loading="isLoading"
            loading-text="正在登录中..."
            >登录</el-button
          >
          <el-button @click="resetForm('formData')">重置</el-button>
        </div>
      </el-card>
    </el-main>
    <el-footer>
      <div class="copyright_info">
        <span>Powered by Tongren University</span>
      </div>
    </el-footer>
  </el-container>
</template>

<script >
export default {
  data() {
    var checkAccount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("账号不能为空"));
      } else {
        callback();
      }
    };
    var checkPass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var checkCode = (rule, value, callback) => {
    	if(value === ""){
    		return callback(new Error("请输入验证码"));
    	}else {
        callback();
      }
    };
    return {
      isLoading: false,
      imgBase64Code: "",
      formData: {
        account: "",
        password: "",
        validcode: "",
        verify_value:"",
      },
      rules: {
        account: [{ validator: checkAccount, trigger: "blur" }],
        password: [{ validator: checkPass, trigger: "blur" }],
        validcode: [{ validator: checkCode, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getImg();
  },
  
  methods: {
    //获取验证码图片
    getImg() {
      this.$store.dispatch("user/getVolidCode").then((res) => {
        var { data } = res;
        this.imgBase64Code = data.captcha;
       	//console.log(data.captcha)
       	this.formData.verify_value = data.verify_value
       	
      });
    },
    submitForm(formData) {
      // 把加载状态改成true
      this.isLoading = true;
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("user/login", this.formData)
            .then((res) => {
              this.$message.success("登录成功！");
              this.$router.push("/home");
              // 把加载状态改成false
              this.isLoading = false;
            })
            .catch((error) => {
              // 把加载状态改成false
              this.getImg();
              this.formData.validcode = ""
              this.isLoading = false;
            });
        } else {
          console.log("error submit!!");
          this.isLoading = false;
          return false;
        }
      });
    },
    resetForm(formData) {
      this.$refs[formData].resetFields();
    },
  },
};
</script>

<style scoped>
.login{
  background-color: #304156;
  height: 100vh;
}
.login_pannel {
  margin: auto;
  margin-top: 20vh;
  width: 29rem;
  border-radius: 20px;
}

.login_pannel_title {
  margin: auto;
  margin-bottom: 5%;
  text-align: center;
}

.login_pannel_form {
  margin: 12% 0;
}

.login_pannel_button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  /*外层元素属性设置为center*/
  width: 25rem;
}

.copyright_info {
  margin-top: 1vh;
  text-align: center;
  color: #ccc;
}
</style>